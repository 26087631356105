import React, { useState } from "react";
import PropTypes from "prop-types";

import { DROP_SVG } from "../../../constants/svgConstants";
import { useTranslation } from "next-i18next";
function ModalDropdown({
  items,
  activeItem: { title: activeItemTitle },
  setActiveItem,
  extraStyles,
}) {
  const [t] = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <div className="modal-dropdown" style={{ ...extraStyles }}>
      <div
        className={`modal-dropdown__button ${
          openDropdown ? "modal-dropdown__button_active" : ""
        }`}
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <span className="modal-dropdown__button-text">
          {t(activeItemTitle)}
        </span>
        {DROP_SVG}
      </div>

      {openDropdown && (
        <div className="modal-dropdown__wrapper">
          <div className="modal-dropdown__inner">
            {items
              .filter(({ title }) => title !== activeItemTitle)
              .map(({ id, title }) => (
                <div
                  className="modal-dropdown__item"
                  key={id}
                  onClick={() => {
                    setOpenDropdown(false);
                    setActiveItem({ id, title });
                  }}
                >
                  {t(title)}
                </div>
              ))}
          </div>
        </div>
      )}
      <style jsx global>{`
        @import "./ModalDropdown.scss?";
      `}</style>
    </div>
  );
}

ModalDropdown.propTypes = {
  items: PropTypes.array,
  activeItem: PropTypes.object,
  setActiveItem: PropTypes.func,
  extraStyles: PropTypes.object,
};

ModalDropdown.defaultProps = {
  items: [],
  activeItem: {},
  setActiveItem: () => {},
  extraStyles: {},
};

export default ModalDropdown;
