import React, { useState, useMemo, useEffect, memo } from "react";
import PropTypes from "prop-types";

import InputBlock from "./InputBlock";
import ModalDropdown from "../ModalDropdown/ModalDropdown";

import { GO_BACK_SVG } from "../../../constants/svgConstants";

import AvatarInputFile from "../../pagesComponents/Clubs/components/ClientFamily/AvatarInputFile";
import { useTranslation } from "next-i18next";
import { Button } from "../../pagesComponents/AddClub/components/common/Button";

const SignUpAlertBlock = memo(
  ({
    title,
    subtitle,
    prevButtonTitle,
    skipButtonTitle,
    nextButtonTitle,
    errorBlockTitle,
    infoButton,
    infoQuestion,
    secondInfoQuestion,
    secondInfoButton,
    navButtons,
    inputsArray,
    onClickNextButton,
    onClickInfoButton,
    onClickSecondInfoButton,
    onClickPrevButton,
    onClickSkipButton,
    setActiveNavPage,
    isPrevButton,
    isSkipButton,
    isFullSkipButton,
    isErrorBlock,

    isFamily = false,
    onChange = () => null,
    withUrl = false,
    file = "",
    children,

    isClientProfile = false,
  }) => {
    const [t, { language }] = useTranslation();

    const { showInfoBlock, showSecondInfoBlock } = useMemo(() => {
      const showInfoBlock = !!(infoQuestion || infoButton);

      const showSecondInfoBlock = !!(secondInfoQuestion || secondInfoButton);
      if (prevButtonTitle || skipButtonTitle)
        return {
          prevTitle: prevButtonTitle,
          skipTitle: skipButtonTitle,
          showInfoBlock,
          showSecondInfoBlock,
        };

      return {
        showInfoBlock,
        showSecondInfoBlock,
      };
    }, [
      language,
      prevButtonTitle,
      skipButtonTitle,
      infoQuestion,
      infoButton,
      secondInfoQuestion,
      secondInfoButton,
    ]);

    const [activeFocusInput, setActiveFocusInput] = useState(0);
    const [isInputError, setIsInputError] = useState(false);

    // при Enter на последнем input нажимаем кнопку далее
    useEffect(() => {
      if (
        children ||
        activeFocusInput !== inputsArray.length ||
        !inputsArray.length
      )
        return;

      return onClickNextButton();
    }, [activeFocusInput]);

    // делаем фокус на input с первой ошибкой
    useEffect(() => {
      const check = () => {
        const inputErrorIndex = [];
        inputsArray.forEach(({ errorMessage }, index) => {
          if (!!errorMessage) return inputErrorIndex.push(index);
        });

        if (inputErrorIndex.length) {
          setIsInputError(true);
          setActiveFocusInput(inputErrorIndex[0]);
        } else setIsInputError(false);
      };

      check();
    }, [inputsArray]);

    return (
      <>
        {title && <h2 className="sign-up-section__title">{title}</h2>}
        {subtitle && (
          <div className="sign-up-section__subtitle">{subtitle}</div>
        )}

        {isFamily ? (
          <AvatarInputFile
            onChange={onChange}
            value={file}
            className={"family__card"}
            withUrl={withUrl}
          />
        ) : isClientProfile ? (
          navButtons
        ) : (
          !!navButtons.length && (
            <div className="sign-up-section__buttons-block">
              {navButtons.map(({ slug, title }) => (
                <button
                  key={slug}
                  className="sign-up-section__search-button"
                  onClick={() => setActiveNavPage(slug)}
                >
                  {title}
                </button>
              ))}
            </div>
          )
        )}

        {isErrorBlock && (
          <div className="sign-up-section__error-block">{errorBlockTitle}</div>
        )}

        {!!inputsArray.length && (
          <div className="sign-up-section__inputs-block">
            {inputsArray.map(
              (
                {
                  inputType,
                  placeholderValue,
                  onChange,
                  value,
                  errorMessage,
                  onFocusFunction,
                  onBlurFunction,
                  inputRef,
                  isRequired,
                  inputProps,
                  name,
                  id,
                  isDropdown,
                  dropdownItems,
                  dropdownActiveItem,
                  dropdownSetActiveItem,
                },
                index
              ) => {
                if (isDropdown)
                  return (
                    <ModalDropdown
                      extraStyles={{ marginBottom: "20px", zIndex: 1000 - id }}
                      key={id ? id : placeholderValue}
                      items={dropdownItems}
                      activeItem={dropdownActiveItem}
                      setActiveItem={dropdownSetActiveItem}
                    />
                  );
                return (
                  <InputBlock
                    key={id ? id : placeholderValue}
                    isRequired={isRequired}
                    inputType={inputType}
                    inputRef={inputRef}
                    placeholderValue={placeholderValue}
                    onChange={onChange}
                    value={value}
                    name={name}
                    errorMessage={errorMessage}
                    onFocusFunction={onFocusFunction}
                    onBlurFunction={onBlurFunction}
                    index={index}
                    activeFocusInput={activeFocusInput}
                    setActiveFocusInput={setActiveFocusInput}
                    isInputError={isInputError}
                    inputProps={inputProps}
                  />
                );
              }
            )}
          </div>
        )}

        {children}

        {showSecondInfoBlock && (
          <div className="sign-up-section__second-info-block">
            <span className="sign-up-section__second-info-question">
              {secondInfoQuestion}
            </span>
            <span
              className="sign-up-section__second-info-button"
              onClick={onClickSecondInfoButton}
            >
              {secondInfoButton}
            </span>
          </div>
        )}

        <button
          className="sign-up-section__reg-button"
          onClick={onClickNextButton}
        >
          {nextButtonTitle}
        </button>

        {isPrevButton && (
          <Button
            className={"sign-up-section__prev-button"}
            onClick={onClickPrevButton}
            style={
              isFullSkipButton
                ? { width: "100%", marginTop: "20px", justifyContent: "center" }
                : {}
            }
          >
            {GO_BACK_SVG} {t("go_back")}
          </Button>
        )}

        {isSkipButton && (
          <Button
            className={"sign-up-section__skip-button"}
            onClick={onClickSkipButton}
            style={
              isFullSkipButton
                ? { width: "100%", marginTop: "20px", justifyContent: "center" }
                : {}
            }
          >
            {t("skip")} {GO_BACK_SVG}
          </Button>
        )}

        {showInfoBlock && (
          <div className="sign-up-section__info-block">
            <span className="sign-up-section__info-question">
              {infoQuestion}
            </span>
            <span
              className="sign-up-section__info-button"
              onClick={onClickInfoButton}
            >
              {infoButton}
            </span>
          </div>
        )}
      </>
    );
  }
);

SignUpAlertBlock.propTypes = {
  nextButtonTitle: PropTypes.string,
  errorBlockTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  infoButton: PropTypes.string,
  infoQuestion: PropTypes.string,
  secondInfoButton: PropTypes.string,
  secondInfoQuestion: PropTypes.string,
  prevButtonTitle: PropTypes.string,
  skipButtonTitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  navButtons: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  inputsArray: PropTypes.array,
  onClickNextButton: PropTypes.func,
  onClickPrevButton: PropTypes.func,
  onClickInfoButton: PropTypes.func,
  onClickSecondInfoButton: PropTypes.func,
  setActiveNavPage: PropTypes.func,
  isPrevButton: PropTypes.bool,
  isSkipButton: PropTypes.bool,
  isFullSkipButton: PropTypes.bool,
  isErrorBlock: PropTypes.bool,

  isClientProfile: PropTypes.bool,
};

SignUpAlertBlock.defaultProps = {
  nextButtonTitle: "",
  errorBlockTitle: "",
  infoButton: "",
  infoQuestion: "",
  secondInfoButton: "",
  secondInfoQuestion: "",
  title: "",
  subtitle: "",
  prevButtonTitle: "",
  skipButtonTitle: "",
  navButtons: [],
  inputsArray: [],
  onClickNextButton: () => {},
  onClickPrevButton: () => {},
  onClickBackButton: () => {},
  onClickInfoButton: () => {},
  onClickSecondInfoButton: () => {},
  setActiveNavPage: () => {},
  isPrevButton: false,
  isSkipButton: false,
  isFullSkipButton: false,
  isErrorBlock: false,
  isClientProfile: false,
};

export default SignUpAlertBlock;
