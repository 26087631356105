import React, { memo } from "react";
import PropTypes from "prop-types";

const Button = memo(
  ({ type = "button", className, onClick, children, style }) => {
    return (
      <button type={type} className={className} onClick={onClick} style={style}>
        {children}
      </button>
    );
  }
);

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  type: "button",
  className: "",
  onClick: () => {},
};

export { Button };
