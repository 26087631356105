import Image from "next/image";
import React, { useRef, useEffect } from "react";

import { PHOTO_SVG } from "../../../../../constants/svgAddClubConstants";

export default function AvatarInputFile({
  htmlId = "file-upload",
  value = "",
  onChange = Function.prototype,
  className = "",
  withUrl = false,
}) {
  const fileRef = useRef();

  useEffect(() => {
    if (value === "") fileRef.current.value = "";
  }, [value]);

  return (
    <>
      {withUrl ? (
        <label htmlFor={htmlId} className={`family-avatar ${className}`}>
          <div className="family-avatar__image-wrapper">
            {value && (
              <Image
                src={value}
                fill
                className="family-avatar__image"
                alt={htmlId}
              />
            )}
          </div>
        </label>
      ) : (
        <label htmlFor={htmlId} className={`family-avatar ${className}`}>
          {value ? (
            <div className="family-avatar__image-wrapper">
              <Image
                alt={htmlId}
                src={window.URL.createObjectURL(value)}
                className="family-avatar__image"
              />
            </div>
          ) : (
            PHOTO_SVG
          )}
        </label>
      )}
      <input ref={fileRef} type="file" id={htmlId} hidden onChange={onChange} />
    </>
  );
}
