import React from "react";

export const PHOTO_SVG = (
  <svg
    width="38"
    height="30"
    viewBox="0 0 38 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.7357 4.67391C35.9772 3.87681 34.9297 3.4058 33.7376 3.4058H27.7414V3.33333C27.7414 2.42754 27.3802 1.55797 26.7662 0.978261C26.1521 0.362319 25.3213 0 24.4183 0H13.5817C12.6426 0 11.8118 0.362319 11.1977 0.978261C10.5837 1.5942 10.2224 2.42754 10.2224 3.33333V3.4058H4.26236C3.07034 3.4058 2.02281 3.87681 1.26426 4.67391C0.505704 5.43478 0 6.52174 0 7.68116V25.7246C0 26.9203 0.469582 27.971 1.26426 28.7319C2.02281 29.4928 3.10646 30 4.26236 30H33.7376C34.9297 30 35.9772 29.529 36.7357 28.7319C37.4943 27.971 38 26.8841 38 25.7246V7.68116C38 6.48551 37.5304 5.43478 36.7357 4.67391ZM36.1217 25.7246H36.0856C36.0856 26.3768 35.8327 26.9565 35.3992 27.3913C34.9658 27.8261 34.3878 28.0797 33.7376 28.0797H4.26236C3.61217 28.0797 3.03422 27.8261 2.60076 27.3913C2.1673 26.9565 1.91445 26.3768 1.91445 25.7246V7.68116C1.91445 7.02899 2.1673 6.44927 2.60076 6.01449C3.03422 5.57971 3.61217 5.32609 4.26236 5.32609H11.2338C11.7757 5.32609 12.2091 4.8913 12.2091 4.34783V3.2971C12.2091 2.89855 12.3536 2.53623 12.6065 2.28261C12.8593 2.02899 13.2205 1.88406 13.6179 1.88406H24.4183C24.8156 1.88406 25.1768 2.02899 25.4297 2.28261C25.6825 2.53623 25.827 2.89855 25.827 3.2971V4.34783C25.827 4.8913 26.2605 5.32609 26.8023 5.32609H33.7738C34.424 5.32609 35.0019 5.57971 35.4354 6.01449C35.8688 6.44927 36.1217 7.02899 36.1217 7.68116V25.7246Z"
      fill="#CACACA"
    />
    <path
      d="M19 7.78985C16.5437 7.78985 14.3042 8.80435 12.7148 10.3986C11.0894 12.029 10.1141 14.2391 10.1141 16.7029C10.1141 19.1667 11.1255 21.413 12.7148 23.0072C14.3403 24.6377 16.5437 25.6159 19 25.6159C21.4563 25.6159 23.6958 24.6014 25.2852 23.0072C26.9106 21.3768 27.8859 19.1667 27.8859 16.7029C27.8859 14.2391 26.8745 11.9928 25.2852 10.3986C23.6958 8.80435 21.4563 7.78985 19 7.78985ZM23.9125 21.6667C22.6483 22.8985 20.9144 23.6956 19 23.6956C17.0856 23.6956 15.3517 22.8985 14.0875 21.6667C12.8232 20.3985 12.0646 18.6594 12.0646 16.7391C12.0646 14.8188 12.8593 13.0797 14.0875 11.8116C15.3517 10.5435 17.0856 9.78261 19 9.78261C20.9144 9.78261 22.6483 10.5797 23.9125 11.8116C25.1768 13.0797 25.9354 14.8188 25.9354 16.7391C25.9715 18.6594 25.1768 20.3985 23.9125 21.6667Z"
      fill="#CACACA"
    />
    <path
      d="M31.8593 11.3043C32.8368 11.3043 33.6293 10.5095 33.6293 9.52898C33.6293 8.54848 32.8368 7.75362 31.8593 7.75362C30.8818 7.75362 30.0894 8.54848 30.0894 9.52898C30.0894 10.5095 30.8818 11.3043 31.8593 11.3043Z"
      fill="#CACACA"
    />
  </svg>
);

export const PLUS_SVG = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM9.25 5.75C9.25 5.33579 9.58579 5 10 5C10.4142 5 10.75 5.33579 10.75 5.75V9.25H14.25C14.6642 9.25 15 9.58579 15 10C15 10.4142 14.6642 10.75 14.25 10.75H10.75V14.25C10.75 14.6642 10.4142 15 10 15C9.58579 15 9.25 14.6642 9.25 14.25V10.75H5.75C5.33579 10.75 5 10.4142 5 10C5 9.58579 5.33579 9.25 5.75 9.25H9.25V5.75Z"
      fill="#DA052C"
    />
  </svg>
);

export const CLOSE_SVG = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.75386 0.24614C9.42568 -0.082046 8.89358 -0.082046 8.5654 0.24614L5 3.81154L1.4346 0.246139C1.10642 -0.0820464 0.574325 -0.0820464 0.24614 0.246139C-0.0820456 0.574324 -0.0820459 1.10642 0.24614 1.4346L3.81153 5L0.246139 8.5654C-0.0820465 8.89358 -0.0820463 9.42568 0.246139 9.75386C0.574325 10.082 1.10642 10.082 1.4346 9.75386L5 6.18846L8.5654 9.75386C8.89358 10.082 9.42568 10.082 9.75386 9.75386C10.082 9.42568 10.082 8.89358 9.75386 8.5654L6.18847 5L9.75386 1.4346C10.082 1.10642 10.082 0.574326 9.75386 0.24614Z"
      fill="#CACACA"
    />
  </svg>
);

export const GO_BACK_SVG = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 9.25C15.1642 9.25 15.5 9.58579 15.5 10C15.5 10.4142 15.1642 10.75 14.75 10.75H7.48227L9.20714 12.4749C9.50004 12.7678 9.50004 13.2426 9.20714 13.5355C8.91425 13.8284 8.43937 13.8284 8.14648 13.5355L5.24734 10.6364C4.89587 10.2849 4.89587 9.71508 5.24734 9.3636L8.14648 6.46447C8.43937 6.17157 8.91425 6.17157 9.20714 6.46447C9.50004 6.75736 9.50004 7.23223 9.20714 7.52513L7.48227 9.25H14.75Z"
      fill="#2B2B2C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5Z"
      fill="#2B2B2C"
    />
  </svg>
);
