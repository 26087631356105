import React, { useEffect } from "react";
import PropTypes from "prop-types";

import PhoneInputContainer from "./PhoneInputContainer";

function InputBlock({
  isRequired,
  inputType,
  placeholderValue,
  errorMessage,
  onFocusFunction,
  onBlurFunction,
  index,
  activeFocusInput,
  setActiveFocusInput,
  inputRef,
  isInputError,
  inputProps,
  value,
  name,
  onChange,
}) {
  // фокусируемся на инпуте

  useEffect(() => {
    if (index === -1 || activeFocusInput === -1) return;
    if (!inputRef?.current) return;

    if (index == activeFocusInput) inputRef.current.focus();
    return () => null;
  }, [activeFocusInput, isInputError]);

  // ивен на нажатие клавиш
  useEffect(() => {
    if (index === -1 || activeFocusInput === -1) return;
    if (!inputRef?.current) return;

    const focusFunc = (event) => {
      if (event.key === "Enter") setActiveFocusInput(index + 1);
    };

    inputRef.current.addEventListener("keypress", focusFunc);

    return () => {
      inputRef.current?.removeEventListener("keypress", focusFunc);
    };
  }, [activeFocusInput, index]);

  return (
    <div
      className={
        isRequired
          ? "sign-up-section__input-wrapper sign-up-section__input-wrapper_required"
          : "sign-up-section__input-wrapper"
      }
    >
      {inputType === "tel" ? (
        <PhoneInputContainer
          placeholderValue={placeholderValue}
          errorMessage={errorMessage}
          onFocus={onFocusFunction}
          onBlur={onBlurFunction}
          inputRef={inputRef}
          inputProps={inputProps}
          value={value}
        />
      ) : (
        <input
          {...inputProps}
          {...(inputRef && { ref: inputRef })}
          className={
            errorMessage
              ? "sign-up-section__input sign-up-section__input_error"
              : "sign-up-section__input"
          }
          type={inputType}
          placeholder={placeholderValue}
          {...(name && { name })}
          {...(value && { value })}
          {...(onChange && { onChange })}
          onFocus={({ target: { value } }) => {
            onFocusFunction({ value, inputRef });
          }}
          onBlur={({ target: { value } }) => {
            onBlurFunction({ value, inputRef });
          }}
        />
      )}

      {errorMessage && (
        <div className="sign-up-section__input-error-message">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}

InputBlock.propTypes = {
  isRequired: PropTypes.bool,
  inputType: PropTypes.string,
  placeholderValue: PropTypes.string,
  errorMessage: PropTypes.string,
  onFocusFunction: PropTypes.func,
  onBlurFunction: PropTypes.func,
  index: PropTypes.number,
  activeFocusInput: PropTypes.number,
  isInputError: PropTypes.bool,
  inputProps: PropTypes.object,
  errorButtonTitle: PropTypes.string,
  errorButtonFunc: PropTypes.func,
};

InputBlock.defaultProps = {
  isRequired: false,
  inputType: "text",
  placeholderValue: "",
  errorMessage: "",
  onFocusFunction: () => {},
  onBlurFunction: () => {},
  index: -1,
  activeFocusInput: -1,
  isInputError: false,
  inputProps: {},
  errorButtonTitle: "",
  errorButtonFunc: () => {},
};

export default InputBlock;
